<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الطلاب الذين ليس لديهم فاتورة في هذه السنة الدراسية</h1>
      <!-- <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" :block="isScreenXs">
              اضافة صرف <v-icon right> fa-plus </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row> -->
      <v-row class="mt-5">
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="class_data.class_id"
            :items="class_data.list"
            :loading="class_data.loading"
            :item-text="item => item.class_name + ' - ' + item.leader"
            item-value="_id"
            clearable
            outlined
            dense
            label="الصف والشعبة"
            @change="getDataAxios"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white">fa-search</v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props"> {{ table.totalData - props.index }} </template>
            <template v-slot:item.account_division_current="{ item }">
              {{ item.account_division_current.class_name + '__' + item.account_division_current.leader }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-bell
                  </v-icon>
                </template>
                <span>ارسال اشعار</span>
              </v-tooltip>
              <v-tooltip bottom v-if="account_type === 'manager'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF8A80" v-bind="attrs" size="18" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">ارسال اشعار الى {{ editedItem.account_name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    rows="4"
                    row-height="20"
                    :rules="rules.notificationBody"
                    v-model="notificationBody"
                    label="المحتوى"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"
          >
            ارسال
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete.open" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="dialogDelete.loading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  data () {
    return {
      // billsTypeSelected: null

      rules: {
        notificationBody: [value => !!value || 'المحتوى مطلوب'],
      },

      dialogDelete: {
        open: false,
        loading: false,
        item: {},
      },

      notificationBody: 'يرجى تسديد القسط الدراسي',

      content_url: null,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      isScreenXs: false,

      tableOptions: {},

      account_type: null,

      editedItem: {},

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      class_data: {
        loading: false,
        list: [],
        class_id: null,
      },

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          {
            text: 'اسم الطالب',
            sortable: false,
            value: 'account_name',
          },
          { text: 'الهاتف', value: 'account_mobile' },
          { text: 'الصف والشعبة', value: 'account_division_current' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    tableOptions: {
      handler () {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler (search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    '$vuetify.breakpoint': {
      handler () {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },
  },

  created () {
    this.getClassSchool()
    this.account_type = JSON.parse(localStorage.getItem('results')).account_type
  },

  methods: {
    async getDataAxios () {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getStudentThatHasNoBills({
        study_year,
        page,
        limit: itemsPerPage,
        search,
        class_id: this.class_data.class_id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
      }
    },

    async getClassSchool () {
      this.class_data.loading = true

      const response = await Api.getClassSchool()
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.class_data.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.class_data.loading = false
        this.class_data.list = response.data.results
      }
    },

    search () {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    editItem (item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform () {
      this.dialogEdit.open = true
      this.dialogEdit.loading = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.studentSendNotification({
        account_id: this.editedItem._id,
        study_year,
        notificationBody: this.notificationBody,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    deleteItem (item) {
      this.dialogDelete.item = { ...item }
      this.dialogDelete.open = true
    },

    async deleteItemConfirm () {
      this.dialogDelete.loading = true

      const response = await Api.removeStudentFromStudentThatHasNoBills(this.dialogDelete.item._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogDelete.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dialogDelete.loading = false
        this.dialogDelete.open = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },
  },
}
</script>
